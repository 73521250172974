import apiAction from '@ynap/api-fetch-action';
import { getContentByPageKey } from '../../api-utils';

const fetchMenus = (forwardDate, gender) => (dispatch, getState) => {
    const { config, locale, countries } = getState();
    const { brandId, coremedia } = config;
    const { internal } = coremedia;
    const { baseUrl, clientId } = internal;
    const { language, country } = locale;
    const currentCountry = countries.response.body.find(each => each.countryCode.toLowerCase() === country.toLowerCase());
    const cmLocale = currentCountry.languages[language]
        ? currentCountry.languages[language].locale
        : currentCountry.languages[currentCountry.preferredLanguage].locale;

    const request = getContentByPageKey({
        baseUrl,
        country,
        language,
        clientId,
        brandId,
        forwardDate,
        cmLocale,
        pageKey: gender ? `sitefurniture-${gender}` : 'sitefurniture-womens'
    });

    return dispatch(apiAction(request, 'FETCH_MENUS'));
};

export default fetchMenus;

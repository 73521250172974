import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withOptimizely } from '@optimizely/react-sdk';
import BEMHelper from '@ynap/bem-helper';
import ImageWithSkeleton from '@ynap/image/src/ImageWithSkeleton/ImageWithSkeleton';
import dispatch from '@ynap/analytics/src/dispatch';
import { getPromoClickData } from './analytics';
import getTargetURL from '../../Utils/getTargetURL';

const bem = new BEMHelper('Promo');

class Promo extends PureComponent {
    trackPromoClick(promoElement, categoryName) {
        const { optimizely } = this.props;
        const promoData = getPromoClickData(promoElement, categoryName);
        const fRLayoutVariant = promoElement.layoutVariant === "site-furniture-secondary-menu-front-row-promo";
        optimizely.onReady().then(() => {
            optimizely.track('global_meganav_clicks');
            optimizely.track('global_navandsearch_clicks');
            fRLayoutVariant && optimizely.track('global_frontrow_banner');
        });

        if (promoData) {
            dispatch(promoData);
        }
    }

    render() {
        const { item, position, hover, baseURL, layoutTwoOneClass, mobile } = this.props;
        const tryGetPromoData = item => {
            if (!item || !item.items || !item.items[0]) {
                return null;
            }

            const element = item.items[0];

            if (!element || !element.picture || !element.picture.imageUrl || (!element.targetURL && !element?.target?.href)) {
                return null;
            }

            return {
                element,
                promoData: {
                    imageUrl: element.picture.imageUrl,
                    targetURL: getTargetURL(element),
                    title: element.title,
                    teaserText: element.teaserText
                }
            };
        };

        const getHeight = (mobile, layoutTwoOneClass) => {
            if (layoutTwoOneClass) {
              return mobile  ? "191px" : "300px";
            } else {
              return "";
            }
        };

        const getWidth = (mobile, layoutTwoOneClass) => {
            if (layoutTwoOneClass) {
              return mobile  ? "315px" : "500px";
            } else {
              return null;
            }
        };

        const getAspectRatio = (layoutTwoOneClass) => {
            if (layoutTwoOneClass) {
              return null;
            } else {
              return "1:1";
            }
        };

        const height = getHeight(mobile, layoutTwoOneClass)
        const width = getWidth(mobile, layoutTwoOneClass)
        const aspectRatio = getAspectRatio(layoutTwoOneClass)

        let promo = tryGetPromoData(item.items[position]);

        if (!promo) {
            promo = tryGetPromoData(item.items[position + 1]);
        }

        // this fix is for backward compatibility until the CMS configuration is updated
        const baseUrl = promo?.promoData.imageUrl.charAt(0) === '/' ? baseURL : ''

        return promo ? (
            <div className={bem('promo')}>
                <a href={promo.promoData.targetURL} alt={promo.promoData.title} onClick={() => this.trackPromoClick(promo.element, item.title)}>
                    <div className={bem('image', null, layoutTwoOneClass)}>
                        {hover && <ImageWithSkeleton src={`${baseUrl}${promo.promoData.imageUrl}`} alt={promo.promoData.title} aspectRatio={aspectRatio} height={height} width={width} />}
                    </div>
                </a>
                {this.props.layoutTwoOneClass ? <div className={bem('promoSubTitle', null, layoutTwoOneClass)}>{promo.promoData.teaserText}</div> : ''}
                <div className={bem('promoTitle', null, layoutTwoOneClass)}>{promo.promoData.title}</div>
            </div>
        ) : null;
    }
}

Promo.propTypes = {
    item: PropTypes.object,
    position: PropTypes.number,
    hover: PropTypes.bool,
    baseURL: PropTypes.string,
    optimizely: PropTypes.shape({
        track: PropTypes.func,
        onReady: PropTypes.func
    })
};

export default withOptimizely(Promo);

import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';

const bem = new BEMHelper('AffirmUSFooterInfo');

const AffirmUSFooterInfo = ({ messages }) => {
    const { heading, descriptionBeforeLink, descriptionAfterLink, affirmLink, linkText, href } = messages?.affirmUSFooterInfo;
    return (
        <div className={bem()}>
            <div className={bem('heading')}>
                <h2>{heading()}</h2>
                <p>
                    {descriptionBeforeLink()}{' '}
                    <a className={bem('affirmLink')} href={affirmLink()} target="_blank" rel="noopener noreferrer">
                        {affirmLink()}
                    </a>
                    {descriptionAfterLink()}
                </p>
                <a className={bem('link')} href={href()}>
                    {linkText()}
                </a>
            </div>
        </div>
    );
};

AffirmUSFooterInfo.propTypes = {
    messages: PropTypes.object.isRequired
};

export default AffirmUSFooterInfo;

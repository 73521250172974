import { authenticatedRequest } from '@ynap/api-utils';
import invariant from 'invariant';

const getContentByPageKey = params => {
    const { baseUrl, brandId, clientId, cmLocale, country, forwardDate, language, pageKey } = params;

    invariant(baseUrl, 'baseUrl is a required param of getContentByPageKey()');
    invariant(brandId, 'brandId is a required param of getContentByPageKey()');
    invariant(clientId, 'clientId is a required param of getContentByPageKey()');
    invariant(cmLocale, 'cmLocale is a required param of getContentByPageKey()');
    invariant(country, 'country is a required param of getContentByPageKey()');
    invariant(language, 'language is a required param of getContentByPageKey()');
    invariant(pageKey, 'pageKey is a required param of getContentByPageKey()');

    const headers = {
        'Content-Type': 'application/json',
        'x-ibm-client-Id': clientId
    };

    let url = `${baseUrl}blueprint/servlet/contentbypage/store/${brandId}_${country}/key/${pageKey}?langId=${cmLocale}&mv=gitMarkdown`;

    if (forwardDate) {
        url += `&forwardDate=${forwardDate}`;
    }

    return authenticatedRequest(new Request(url, { headers: new Headers(headers), method: 'GET' }));
};

export default getContentByPageKey;

import { getTags } from '@ycos/coremedia-renderer/src/utils/tagsUtils';

const getMenus = state => {
    if (state.menus.error || !state.menus.response.body.SFHeader || state.menus.response.body.SFHeader.length < 1) {
        return {
            responseError: true
        };
    }
    const headerItems = state.menus.response.body.SFHeader[0].items;
    const footerMenu = { items: [state.menus.response.body.SFFooter[0]] };
    const headerMenu = {
        items: stripHiddenItems(headerItems)
    };
    const privateSale = getPrivateSale([state.menus.response.body.SFPrivateEvents?.[0]]);
    const locale = state.locale;
    const benefitsBar = state.menus.response.body.SFBenefitsBar?.[0].items;

    // get product listing url template from config
    const getProductCategoryUrl = path => {
        const { locale, config } = state;
        return `/${locale.id.toLowerCase()}${config.productCategoryUrl}${path}`;
    };

    // TODO: Figure out a nice way to do this
    const isSignedIn = state.user.response.isSignedIn;

    return {
        headerMenu,
        footerMenu,
        config: state.config,
        isSignedIn,
        locale,
        getProductCategoryUrl,
        privateSale: privateSale,
        benefitsBar
    };
};

const getPrivateSale = items => {
    const privateSale = items.find(item => item?.target?.key === 'hiddenRibbonPage');
    return privateSale?.items?.map(item => {
        const { tags, target } = item || {};
        const customerSegments = getTags(tags, 'CustomerSegments');

        if (!customerSegments) return null;
        let url = target?.targetURL ? target.targetURL : target?.href;
        return {
            customerSegments,
            linkText: item.title,
            linkUrl: url,
            targetTitle: item?.target?.title,
            bannerText: item.teaserTextPlain
        };
    });
};

//remove all non meganav items
const stripHiddenItems = items => items.filter(item => item.layoutVariant && item.layoutVariant.startsWith('menu-'));

export default getMenus;
